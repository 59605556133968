exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-ae-index-tsx": () => import("./../../../src/pages/AE/index.tsx" /* webpackChunkName: "component---src-pages-ae-index-tsx" */),
  "component---src-pages-ae-terms-and-conditions-address-update-policy-tsx": () => import("./../../../src/pages/AE/terms-and-conditions/address-update-policy.tsx" /* webpackChunkName: "component---src-pages-ae-terms-and-conditions-address-update-policy-tsx" */),
  "component---src-pages-ae-terms-and-conditions-faq-tsx": () => import("./../../../src/pages/AE/terms-and-conditions/faq.tsx" /* webpackChunkName: "component---src-pages-ae-terms-and-conditions-faq-tsx" */),
  "component---src-pages-ae-terms-and-conditions-index-tsx": () => import("./../../../src/pages/AE/terms-and-conditions/index.tsx" /* webpackChunkName: "component---src-pages-ae-terms-and-conditions-index-tsx" */),
  "component---src-pages-ae-terms-and-conditions-joining-offer-tsx": () => import("./../../../src/pages/AE/terms-and-conditions/joining-offer.tsx" /* webpackChunkName: "component---src-pages-ae-terms-and-conditions-joining-offer-tsx" */),
  "component---src-pages-ae-terms-and-conditions-jp-tac-tsx": () => import("./../../../src/pages/AE/terms-and-conditions/jp-tac.tsx" /* webpackChunkName: "component---src-pages-ae-terms-and-conditions-jp-tac-tsx" */),
  "component---src-pages-ae-terms-and-conditions-mg-fraud-warning-tsx": () => import("./../../../src/pages/AE/terms-and-conditions/mg-fraud-warning.tsx" /* webpackChunkName: "component---src-pages-ae-terms-and-conditions-mg-fraud-warning-tsx" */),
  "component---src-pages-ae-terms-and-conditions-privacy-policy-tsx": () => import("./../../../src/pages/AE/terms-and-conditions/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-ae-terms-and-conditions-privacy-policy-tsx" */),
  "component---src-pages-ae-terms-and-conditions-referral-offer-tsx": () => import("./../../../src/pages/AE/terms-and-conditions/referral-offer.tsx" /* webpackChunkName: "component---src-pages-ae-terms-and-conditions-referral-offer-tsx" */),
  "component---src-pages-bh-index-tsx": () => import("./../../../src/pages/BH/index.tsx" /* webpackChunkName: "component---src-pages-bh-index-tsx" */),
  "component---src-pages-bh-terms-and-conditions-address-update-policy-tsx": () => import("./../../../src/pages/BH/terms-and-conditions/address-update-policy.tsx" /* webpackChunkName: "component---src-pages-bh-terms-and-conditions-address-update-policy-tsx" */),
  "component---src-pages-bh-terms-and-conditions-faq-tsx": () => import("./../../../src/pages/BH/terms-and-conditions/faq.tsx" /* webpackChunkName: "component---src-pages-bh-terms-and-conditions-faq-tsx" */),
  "component---src-pages-bh-terms-and-conditions-index-tsx": () => import("./../../../src/pages/BH/terms-and-conditions/index.tsx" /* webpackChunkName: "component---src-pages-bh-terms-and-conditions-index-tsx" */),
  "component---src-pages-bh-terms-and-conditions-joining-offer-tsx": () => import("./../../../src/pages/BH/terms-and-conditions/joining-offer.tsx" /* webpackChunkName: "component---src-pages-bh-terms-and-conditions-joining-offer-tsx" */),
  "component---src-pages-bh-terms-and-conditions-jp-tac-tsx": () => import("./../../../src/pages/BH/terms-and-conditions/jp-tac.tsx" /* webpackChunkName: "component---src-pages-bh-terms-and-conditions-jp-tac-tsx" */),
  "component---src-pages-bh-terms-and-conditions-mg-fraud-warning-tsx": () => import("./../../../src/pages/BH/terms-and-conditions/mg-fraud-warning.tsx" /* webpackChunkName: "component---src-pages-bh-terms-and-conditions-mg-fraud-warning-tsx" */),
  "component---src-pages-bh-terms-and-conditions-privacy-policy-tsx": () => import("./../../../src/pages/BH/terms-and-conditions/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-bh-terms-and-conditions-privacy-policy-tsx" */),
  "component---src-pages-bh-terms-and-conditions-referral-offer-tsx": () => import("./../../../src/pages/BH/terms-and-conditions/referral-offer.tsx" /* webpackChunkName: "component---src-pages-bh-terms-and-conditions-referral-offer-tsx" */),
  "component---src-pages-bh-waitlist-tsx": () => import("./../../../src/pages/BH/waitlist.tsx" /* webpackChunkName: "component---src-pages-bh-waitlist-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

